@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: #1a1a1a;
}

.input-phone-number input:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
